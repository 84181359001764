.action-buttons {
  display: inline-flex;
  margin: 5px 0px 5px 0px;
}

/* Action Buttons */
.action-button {
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}
.action-button:hover {
}
.action-button-text {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}
