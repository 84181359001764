.upgrade-text-1,
.resubscribe-body-1,
.resubscribe-body-2,
.resubscribe-button {
  font-family: 'Montserrat', sans-serif;
}
.resubscribe-modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 10003;
  background: #8e9bb5;
  display: block;
  top: 0;
  left: 0;
  right: 0;
}
.resubscribe-modal-container {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10004;
}
.resubscribe-modal {
  overflow-y: visible;
  position: relative;
  background: #8a96bc;
  /* border: 2px solid orange; */

  text-align: center;
  width: 550px;
  user-select: none;
  border-radius: 10px;
  margin: auto;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.resubscribe-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;

  color: white;
  font-size: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 50px;
}
.resubscribe-title {
  margin: 40px 0px 0px 0px;

  font-size: 30px;
  line-height: 29px;
  letter-spacing: 0.05em;

  color: #ffffff;
}
.resubscribe-body-1,
.resubscribe-body-2 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
}
.resubscribe-body-1 {
  margin: 30px 60px 0px 60px;
}
.resubscribe-body-2 {
  margin: 30px 60px 40px 60px;
  /* color: #ffffff; */
}
.features-background {
  background: white;

  margin: 0px 30px 0px 30px;
  padding: 30px 0px 20px 0px;

  border-radius: 6px;
}
.resubscribe-button {
  margin: 30px 30px 30px 30px;
  padding: 10px 0px 10px 0px;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}
