.unsubscribe-modal-button,
.unsubscribe-modal-title,
.unsubscribe-modal-subtitle,
.unsubscribe-modal-body-text-need-confirmation {
  font-family: 'Montserrat', sans-serif;
}

.unsubscribe-modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 10003;
  background: #8e9bb5;
  top: 0;
  /* top: 0;
  left: 0;
  right: 0; */
}
.unsubscribe-modal-container {
  position: fixed;
  display: flex;
  z-index: 10004;
  height: 100%;
  width: 100%;
}
.unsubscribe-modal {
  position: relative;
  margin: auto;
  top: 0;
  margin-top: 40px;
  width: 450px;
  text-align: center;
  background: #8a96bc;

  border-radius: 10px;
  padding: 0px 40px 0px 40px;

  user-select: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.unsubscribe-modal-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  color: white;
  font-size: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 50px;
}

.unsubscribe-modal-title {
  margin: 40px 0px 0px 0px;

  font-size: 30px;
  font-weight: 500px;
  color: white;
}
.unsubscribe-modal-body {
  margin: 30px 0px 0px 0px;
}
.unsubscribe-body-white-background {
  padding: 20px 0px 20px 0px;
  background: white;
  border-radius: 5px;
  display: inline-flex;
  font-size: 18px;
}
.unsubscribe-modal-subtitle {
  margin: 20px 0px 0px 0px;
  color: white;
  font-size: 18px;
}
.unsubscribe-modal-body-text {
  margin: auto;
}
.unsubscribe-modal-body-text-need-confirmation {
  margin: auto;
  color: white;
}
.unsubscribe-modal-button {
  margin: 60px 0px 60px 0px;
  padding: 10px 30px 10px 30px;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}
