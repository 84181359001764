.footer {
  background-color: #475986;
}
.footer-icon {
  width: 50px;
  height: 50px;
}
.footer-sheerba {
  width: 110px;
  height: 50px;
}
.footer-social-icon {
  width: 30px;
}
