.account-page {
  /* color: red; */
  min-height: 1000px;
}

.info-view-main-area-2 {
  /* height: 710px;
  width: 710px; */
  /* margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem; */
  /* height: 400px; */
  background-color: white;
  border-radius: 10px;
  box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25);
  color: #b5b6b5;
  /* border-radius: 10px 10px 10px 10px; */
}
.info-view-container {
  /* display: flex; */
  /* position: fixed; */
  /* display: flex; */
  /* background-color: red; */
  height: 100%;
  width: 100%;
}

.info-view-background {
  position: absolute;
  width: 120%;
  bottom: 0px;
  height: 130%;
  z-index: -1;
}
.account-alert {
  position: absolute;
  z-index: 10;
}
.account-message-space {
  height: 50px;
}
