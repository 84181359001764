.search-page {
  /* background-color: red; */
  min-height: 1000px;
  /* height: 100px; */
}
.tag-search {
  /* color: red; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* width: 100%; */
  margin-top: 0px;
  padding-top: 0px;
}
.tag-search-bar-area {
  /* background-color: lightblue; */
}
.tag-search-view-search-bar {
  /* margin: auto; */
  /* width: 100%; */
}
.tag-search-field {
  /* flex-basis: 100%; */
}

.subscribe-button,
.feedback-button {
  font-family: Arial, sans-serif;
  font-size: 14px;
  margin: 0px 2px 0px 2px;
  width: 90px;
  color: white;
  background-color: #f2a864;
  padding: 10px 10px 10px 10px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}
