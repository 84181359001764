.free-trial-explanation-text,
.free-trial-text,
.monthly,
.annually,
.pay-frequency,
.coupon-error,
.coupon-success-msg,
.upgrade-text-1 {
  font-family: 'Montserrat', sans-serif;
}

.dollar,
.cents,
.coupon-success-code,
.reg-price-dollar,
.reg-price-cents,
.discount-price-dollar,
.discount-price-cents,
.discounted-annual-discounted-text,
.annual-cost {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.price-modal-area {
  overflow-y: visible;
  position: relative;
  opacity: 1;
  background: #8797bf;
  /* border: 20px solid orange; */

  text-align: center;
  min-width: 380px;
  max-width: 550px;
  height: 850px;
  user-select: none;
  border-radius: 10px;
  margin: auto;

  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}

.price-modal-close {
  /* width: 50px;
  height: 50px; */
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;

  color: white;
  font-size: 20px;
  position: absolute;
  margin: 15px;
  padding: 0px 10px 0px 10px;
  line-height: 50px;

  background-color: #2e3a57;
  border-radius: 6px;
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25); */
}

.mascot-on-price {
  padding: 15px 0px 10px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 160px;
}
.price-header-text {
  margin: 5px 5px 20px 5px;

  text-align: center;

  font-family: 'Montserrat', sans-serif;

  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.05em;

  color: #ffffff;
}
.price-plan-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 210px;
}

.selected-plan-button,
.unselected-plan-button {
  margin: 0px 10px 0px 10px;
  padding: 15px 20px 15px 20px;
  border-radius: 6px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;

  color: #ffffff;
}
/* 
regular: #475986
darker: #3e4d75 
*/

.unselected-plan-button {
  background-color: #475986;
  cursor: pointer;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}
.unselected-plan-button:hover {
  background-color: #3e4d75;
}
.selected-plan-button {
  background-color: #2e3a57;
}

.text-area-page1,
.text-area-page2 {
  overflow-y: visible;
  border-radius: 8px;
  background: #ffffff;
}

.text-area-page1 {
  margin: 40px 30px 50px 30px;
  padding: 40px 0px 45px 0px;
}
.text-area-page2 {
  margin: 40px 30px 30px 30px;
  padding: 50px 0px 5px 0px;
}

/* Free Trial Text */
.free-trial-text {
  margin: 0px 0px 5px 0px;

  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;

  color: #bf4f2c;
}
.free-trial-explanation-text {
  margin: 5px 40px 5px 40px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* line-height: 35px; */

  color: #bf4f2c;
}

/* Cost Section */

.cost {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

/* No Coupon applied yet */

.dollar,
.cents {
  letter-spacing: 0.05em;
  color: #475986;
}
.dollar {
  font-size: 48px;
  line-height: 59px;
}
.cents {
  padding-top: 4px;
  font-size: 24px;
  line-height: 29px;
}

/* Coupon Regular Price */
.reg-price {
  display: flex;
  padding: 10px 10px 0px 0px;
}
.reg-price-dollar {
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */
  letter-spacing: 0.05em;

  color: #475986;
}
.reg-price-cents {
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.05em;

  color: #475986;
}
.cross-out-line {
  /* position: absolute; */
  width: 100px;
  height: 0px;
  left: 133px;
  top: 315px;

  border: 2px solid #f09c4e;
  transform: rotate(160.13deg);
}

/* Coupon Discount Price */
.discount-price {
  display: flex;
  margin: 0px 50px 0px 20px;
}
.discount-price-dollar {
  font-size: 48px;
  line-height: 59px;
  /* identical to box height */
  letter-spacing: 0.05em;

  color: #f09c4e;
}
.discount-price-cents {
  padding-top: 4px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.05em;

  color: #f09c4e;
}

/* Other sections for pricing */

.pay-frequency {
  margin: 5px 0px 5px 0px;
  color: #475986;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.05em;
}
.annual-cost {
  color: #f09c4e;
}
.annual-cost-hidden {
  color: #ffffff;
}
.annual-cost,
.annual-cost-hidden {
  padding: 5px 0px 5px 0px;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 0.05em;
}

.annual-cross-out-line {
  /* position: absolute; */
  width: 50px;
  height: 0px;
  left: 183px;
  top: 405px;

  border: 1px solid #f09c4e;
  transform: rotate(160.13deg);
}

.discounted-annual-cost {
  margin: 10px 0px 5px 0px;
  display: flex;
  justify-content: center;
}
.discounted-annual-original-cost {
  color: #475986;
}
.discounted-annual-discounted-text {
  padding: 0px 0px 0px 20px;
  color: #f09c4e;
}
/* Coupon Success Message */
.coupon-success-msg {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;

  color: #f09c4e;
}
.coupon-success-area-positioning {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
.coupon-success-area {
  padding: 5px 25px 5px 25px;
  background: #fffcdc;
  display: flex;
  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}
.coupon-success-checkmark {
  width: 50px;
  padding: 4px 10px 4px 0px;
  flex-basis: 10%;
}
.coupon-success-code {
  padding: 10px 0px 0px 0px;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.12em;

  color: #f09c4e;
  flex-basis: 50%;
}

/* Upgrade Button */

.price-upgrade-button-annual {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 10px;
}

.price-upgrade-button-monthly {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}

.upgrade-button-cost {
  padding: 12px 30px 12px 30px;
  background: linear-gradient(93.66deg, #ef9b4e 4.11%, #fbae67 86.29%);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
}
.upgrade-button-cost:hover {
  background: #ed9440;
}
.upgrade-button-cost:active {
  background: #d98217;
}

.upgrade-text-1,
.upgrade-text-2 {
  color: white;
}

.upgrade-text-1 {
  font-weight: bold;
}

/* Coupon stuff */

.coupon-form {
}
.coupon-error {
  margin: 5px 0px 5px 0px;
  color: #ce4b5c;
}
.coupon-text {
  padding: 11px 5px 11px 10px;
  border-width: 0px;
  background: #c1cff2;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.coupon-submit {
  margin: 0px 0px 0px 15px;

  color: white;
  padding: 6px 20px 6px 20px;
  background: linear-gradient(93.66deg, #ef9b4e 4.11%, #fbae67 86.29%);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border-width: 0px;
  cursor: pointer;
}
.coupon-submit:hover {
  background: #ed9440;
}
.coupon-submit:active {
  background: #d98217;
  border: none;
  border-radius: 0px;
}
