.expander-image {
}
.expander-header {
  background-color: #fbfbfb;
  padding: 15px 15px 15px 15px;
  text-align: left;

  cursor: pointer;

  color: #5e5e5e;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
  /* background-color: lightcoral; */
}

.expander-body {
  padding: 15px 15px 15px 15px;
  text-align: left;

  color: #5e5e5e;
  background-color: #fbfbfb;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
}
