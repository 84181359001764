.search-bar-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f6f6f6;
}

.tag-search-search-area {
  background-color: gray;
  border-radius: 5px;
  padding-top: 20px;
  /* width: 1500px; */

  /* text-align: center; */
}

.custom-submit-button {
  /* cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  
  border-radius: 20px; */
  font-family: Verdana, Geneva, sans-serif;
  font-size: 16px !important;
  border-radius: 30px !important;
  /* margin: 10px; */
  padding: 0px !important;
}
