.feature-cta-area {
  border-radius: 14px;
  background-color: #879acd;
  overflow: hidden;
  height: 320px;
}
.feature-circle {
  position: absolute;
  top: -300px;
  right: -600px;
  height: 800px;
}
.feature-illustration {
  position: absolute;
  top: -30px;
  right: -20px;
  height: 280px;
}
