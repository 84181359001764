.pricing-header {
  min-height: 70rem;
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 10rem;
  background: url(../../../img/payment_background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0,
        rgb(255, 255, 255) 100%
      ),
      url(../img/new_Background.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; */
}
.pricing-title-section {
  display: block;
  margin-top: 200px;
}
.price-component-positioning {
  /* right: 10px; */
  /* margin: auto; */
  margin-top: 100px;
}
.pricing-title-text {
  /* padding-left: 20%; */
}
.pricing-get-started-button {
  /* width: 25%; */
  /* margin: 30px 0px 0px ; */
}
