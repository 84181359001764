.ten,
.nine,
.eight,
.seven,
.six,
.five,
.four,
.three,
.two,
.one {
  color: white;
  /* padding: 8px; */
  text-align: center;
  border-radius: 12px;
  width: 50px;
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  /* font-size: 13px; */
  color: #475986;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
}

/* .ten {
  background-color: #29bb49;
}
.nine {
  background-color: #82d341;
}
.eight {
  background-color: #75b426;
}
.seven {
  background-color: #bfb92c;
}
.six {
  background-color: #e8bf31;
}
.five {
  background-color: #bf8d2c;
}
.four {
  background-color: #ca7b32;
}
.three {
  background-color: #bf612c;
}
.two {
  background-color: #bf4f2c;
}
.one {
  background-color: #bf2c2c;
} */
