.text-input,
.primary-txt-color,
.submit-button,
.title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
}

.sign-in-modal-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  line-height: 50px;
  color: #f7f7f7;
}

.login-modal-container {
  position: fixed;
  display: flex;
  z-index: 10004;
  height: 100%;
  width: 100%;
  user-select: none;
}

.login-modal {
  width: 750px;
  height: 450px;
  overflow-y: visible;
  position: relative;
  background: #f7f7f7;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sign-in-logo-section {
  margin: 170px 0px 0px 10px;
}

.sign-in-mascot,
.sign-in-logo {
  height: 100px;
}

.register-form,
.sign-in-form {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.register-form {
  margin-top: 60px;
}

.sign-in-form {
  margin-top: 90px;
}

.title {
  margin: 0px 0px 15px 0px;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: 0.05em;

  color: white;
}

.text-input {
  border: 1px solid darkgray;
  width: 250px;
  margin: 5px 0px 5px 0px;
  padding: 8px 0px 8px 10px;
  background: #d8dfef;
  border-radius: 7px;
}

.text-input::placeholder {
  color: gray;
}

.success-txt-color {
  color: #6b81b6;
}

.error-txt-color {
  color: red;
}

.primary-txt-color {
  color: black;
}

.sign-in-error-message {
  /* background: #d8dfef; */
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  /* background-color: brown; */
  border-radius: 7px;
}

.show-sign-in-error {
  background: #d8dfef;
}

.hide-sign-in-error {}

/* 40 33 30  */
.submit-button {
  margin-top: 15px;
  padding: 10px 0px 10px 0px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  font-size: 20px;
  font-weight: 10px;
  line-height: 24px;
  letter-spacing: 0.05em;
}

.submit-button:disabled,
.submit-button[disabled] {
  background: gray;
  cursor: default;
}

.switch-mode-button {
  margin: 15px 0px 10px 0px;
  text-align: right;
  font-family: Cousine;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: -0.04em;

  color: #d8dfef;
  cursor: pointer;
}