/* Expandable View */

.expandableView {
  margin: 10px;
  background-color: #f5f5f5;
  display: inline-flex;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  color: lightgray;
  box-shadow: 5px 5px 20px;
}
.expandableViewImage {
  margin: 15px 20px 15px 15px;
  /* display: inline-flex; */
  max-height: 200px;
  max-width: 280px;
  border-radius: 10px;
  color: #b5b6b5;
  box-shadow: 5px 5px 20px;
}

.expandableViewTitleSection {
  padding: 5px 10px 5px 10px;
  margin: 10px 0px 0px 0px;
  font-size: 14px;
  /* font-weight: bold; */
  cursor: pointer;
  border-radius: 8px;
  color: #475986;
  text-decoration-color: black;
  text-decoration: none;
  flex-basis: 100%;
  /* background: green; */
  /* height: 60px; */
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}
.expandableViewTitleSection:link {
  text-decoration-color: #354264;
  text-decoration: none;
}
.expandableViewTitleSection:hover {
  /* background-color: #e6e6e6; */
  text-decoration-color: #354264;
  text-decoration: underline;
  color: #354264;
}

/* Major Sections */

.expandableViewCenterSection,
.expandableViewRightSection {
  flex-wrap: wrap;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}
.expandableViewCenterSection {
  margin-left: 10px;
  display: inline-flex;
  margin-right: 10px;
  width: 480px;
  height: 230px;
  /* background-color: blue; */
}

.expandableViewRightSection {
  /* margin: 30px 10px 10px 10px; */
  /* height: 280px; */
  width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* background-color: red; */
}

/* Store Section */
.expandableViewListingFavoriteSection {
  flex-wrap: wrap;

  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.expandableViewStoreSection {
  flex-wrap: wrap;
  margin: -2px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  /* height: 100px; */
  flex-basis: 33%;
  color: #475986;
  cursor: pointer;
  /* border-width: 1px;
  border-style: solid; */
  border-radius: 10px;
  text-decoration-color: black;
  text-decoration: none;
  display: inline-flex;
  /* background: red; */
  height: 65px;
}
.expandableViewStoreSection:hover {
  background-color: #e6e6e6;
  text-decoration: none;
  /* text-decoration-color: black;
  text-decoration: underline; */
}
.expandableViewStoreSection:link {
  text-decoration: none;
}

.expandableViewStoreTitle {
  font-size: 12px;
  /* flex-grow: 1; */
  flex-basis: 100%;
  /* font-weight: bold; */

  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: #475986;

  text-decoration-color: black;
  text-decoration: none;
  text-align: center;
  padding: 0px 0px 2px 3px;
}

.expandableViewStoreSalesHeader,
.expandableViewStoreRatingHeader,
.expandableViewStoreRatingCountHeader,
.expandableViewStoreSalesInfo,
.expandableViewStoreRatingInfo,
.expandableViewStoreRatingCountInfo {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.expandableViewStoreSalesHeader,
.expandableViewStoreRatingHeader,
.expandableViewStoreRatingCountHeader {
  color: #6b81b6;
  font-size: 11px;
  height: 15px;
}

.expandableViewStoreSalesInfo,
.expandableViewStoreRatingInfo,
.expandableViewStoreRatingCountInfo {
  color: #354264;
  font-size: 10px;
}

.expandableViewStoreSalesHeader,
.expandableViewStoreSalesInfo {
  flex-basis: 33%;
}

.expandableViewStoreRatingHeader,
.expandableViewStoreRatingInfo {
  flex-basis: 30%;
}

.expandableViewStoreRatingCountHeader,
.expandableViewStoreRatingCountInfo {
  flex-basis: 30%;
}

/* Favorer section */

.expandableViewListingFavoriteSection {
  cursor: pointer;
  flex-basis: 55%;

  /* background: red; */
  display: position;
  height: 65px;
  border-radius: 10px;

  margin: 0px 0px 0px 0px;
}

.expandableViewListingHeader,
.expandableViewListingInfoField {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #354264;
}
.expandableView-favorersButton {
  width: 150px;
  margin-top: 5px;
  margin-left: 72px;
  /* margin-right: auto; */
  padding: 5px 0px 5px 0px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
}
.expandableView-favorersButton:hover {
  background-color: #e6e6e6;
}
.expandableViewListingHeader {
  font-size: 12px;
  font-weight: bold;
  margin: 0px 0px 0px 0px;
}
.expandableViewListingInfoField {
  padding-top: 2px;
  font-size: 12px;
  font-weight: bold;
}

/* Price Section */
.expandableViewPriceInfoSection {
  /* margin-top: 10px; */
  margin-left: auto;
  margin-right: auto;
  flex-basis: 80%;
  display: inline-flex;
  height: 45px;
  /* background: lightblue; */
}
.expandableViewPriceFirstSection {
  flex-basis: 33%;
  padding-left: 20px;
  text-align: center;
}
.expandableViewPriceSection {
  flex-basis: 33%;
  text-align: center;
}
.expandableViewPriceSectionHeader {
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  color: #6b81b6;
}
.expandableViewPriceSectionBody {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #354264;
}

/* Shipping Section */

.expandableViewShippingSection {
  flex-basis: 34%;
  color: #354264;
  /* background: lightgreen; */
  /* height: 45px; */
  margin: 0px 0px 0px 0px;
  text-align: center;
}
.expandableViewShippingHeader,
.expandableViewShippingInfoField {
  flex-grow: 1;
  flex-basis: 100%;
  color: #354264;
}

.expandableViewShippingHeader {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
}
.expandableViewShippingInfoField {
  font-family: 'Montserrat', sans-serif;
  margin-top: 6px;
  font-size: 13px;
  color: #475986;
}

/* Creation Date Section  */
.expandableViewCreationDateSection {
  /* margin-top: 25px; */
  text-align: center;
  /* background-color: lightpink; */
  flex-basis: 33%;
}

.expandableViewInfoTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  color: #6b81b6;
}

.expandableViewCreationDateDateString,
.expandableViewCreationDateTimeString {
  /* margin-top: 5px; */
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: #475986;
}

.expandableViewCreationDateDateString {
}

.expandableViewCreationDateTimeString {
}

/* standard blue
  6b81b6 - standard | 57
  788cba - lighter | 60
  546eab - darker | 50
  */

/* standard orange
  f2a864 - standard | 67
   - lighter
  f09642 - darker | 60
   */
