/* Tag Section */
.tagComponentMainArea {
  /* background: green; */
}
.tagComponentTagTitleArea {
  margin: 10px 0px 0px 0px;
  padding-bottom: 5px;
  display: inline-flex;
  flex-grow: 1;
  flex-basis: 100%;
}

.tagComponentTagTitleText,
.tagComponentTagCopyAllButton {
  font-family: 'Montserrat', sans-serif;
}

.tagComponentTagTitleText {
  font-size: 14px;
  /* font-weight: bold; */
  color: #354264;
  text-align: center;
  width: 300px;
}
.tagComponentTagEmptyText {
  color: #354264;
  text-align: center;
}
.tagComponentTagCopyAllButton {
  /* background-color: #f09642; */
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  color: #354264;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  width: 280px;
}
.tagComponentTagCopyAllButton:hover {
  background-color: #e6e6e6;
}
.tagComponent-tag-section {
  display: inline-flex;
  flex-wrap: wrap;
}
.tagComponent-tags-locked-icon {
  margin: -5px 0px 0px 0px;
  padding: 0px 0px 0px 15px;
  width: 50px;
}
