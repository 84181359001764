.analytics-wrapper {
  /* position: relative; */
  /* flex: 1; */
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.analytic-tools-wrapper {
  /* display: flex;
  flex-wrap: wrap;
  margin: auto; */
  /* width: 100%; */
}

.non-chart-analytics {
  display: flex;
  /* margin: auto; */
  /* width: 100%; */
  /* margin: 10px; */
}

/* Charts */

.past-chart,
.future-chart,
.future-chart-blurred,
.past-chart-placeholder,
.past-chart-insufficient-data,
.future-chart-placeholder,
.future-chart-insufficient-data {
  background-color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  /* margin-left: auto;
  margin-right: auto; */
  box-shadow: 5px 5px 20px;
  width: 315px;
  color: #d0d0d0;
}
.chart-title-blurred,
.chart-title {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  border-radius: 10px;
}
.chart-title-blurred {
  color: gray;
}
.chart-title {
  background: white;
  color: #354264;
  /* cursor: pointer; */
}
/* .chart-title:hover {
  background-color: lightgray;
} */

.chart-body {
  color: #354264;
  font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  padding-top: 5px;
}
.past-chart {
}

.past-chart-placeholder,
.past-chart-insufficient-data,
.future-chart-placeholder,
.future-chart-insufficient-data {
  height: 159px;
}

.past-chart-placeholder,
.past-chart-insufficient-data {
  left: 30px;
}
.past-chart-insufficient-data {
  text-align: center;
}
.past-chart-insufficient-data-img,
.future-chart-insufficient-data-img {
  margin-top: 20px;
  width: 50px;
}
.past-chart-insufficient-title {
  margin: 20px 20px 0px 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #475986;
}

.future-chart-placeholder,
.future-chart-insufficient-data {
}
.future-chart,
.future-chart-blurred {
}
.future-chart-blurred {
  cursor: pointer;
}
.future-chart-blurred:hover {
  background-color: lightgray;
}
.future-chart-insufficient-data {
  text-align: center;
}
.future-chart-insufficient-title {
  margin: 20px 20px 0px 20px;

  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #475986;
}

.blur-chart-area {
  position: absolute;
  z-index: 998;
  /* width: 100%;
  height: 100%; */
  /* top: inherit - 200px; */
  width: 315px;
  height: 100px;
  backdrop-filter: blur(5px);
}
.chart-locked-icon {
  position: absolute;
  z-index: 999;
  left: 45%;
  right: 50%;
  /* top: 50px; */
  /* right: 160px; */
  width: 50px;
}

/* Info Section */

.info-section-placeholder,
.info-section {
  /* margin-top: 20px; */

  color: #d0d0d0;
  box-shadow: 5px 5px 20px;

  background-color: white;
  text-align: center;
  width: 100px;
  height: 100px;

  border-radius: 10px;

  user-select: none;
}
.info-section {
  cursor: pointer;
}
.stats-area-unsubscribed:hover,
.info-section:hover {
  background-color: lightgray;
}
.competition-score-text-unsubscribed,
.competition-score-text-placeholder,
.competition-score-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 15px 6px 8px 10px;
}
.competition-score-text-placeholder {
  color: gray;
}
.competition-score-text {
  color: #f09c4e;
}
.competition-score-text:hover {
  color: #be7c3e;
}
.blur-score-area {
  position: absolute;
  /* top: 40px; */
  margin-top: 50px;
  width: 100px;
  height: 50px;
  backdrop-filter: blur(5px);
}
.competition-score-locked-icon {
  position: absolute;
  /* top: 8px;
  right: 30px; */
  width: 30px;
  left: 40%;
  right: 50%;
}

/* Stats Component */

.stats-area-unsubscribed,
.stats-area {
  /* margin-top: 20px; */
  position: relative;

  /* left: 950px; */

  color: #d0d0d0;
  background-color: white;
  text-align: center;
  width: 200px;
  height: 100px;

  box-shadow: 5px 5px 20px;
  border-radius: 10px;
}
.stats-area-unsubscribed {
  cursor: pointer;
}

.stats-area-info-area {
  /* background-color: lightpink; */
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 10px; */
  /* display: inline-flex; */
}

.stat-titles-row,
.stat-data-row {
  /* flex-basis: 100%; */
  width: 100%;
  display: inline-flex;
  /* background-color: lightsalmon; */
}

.stat-title,
.stat-data {
  text-align: center;
  flex-wrap: wrap;
  flex-basis: 33%;
  padding-left: 3px;
  padding-right: 3px;
  margin: auto;
}

.stat-title-placeholder,
.stat-title-price,
.stat-title-fav,
.stat-title-sale {
  text-align: center;
  flex-wrap: wrap;

  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 9px;
}
.stat-title-price,
.stat-title-fav,
.stat-title-sale {
  color: #f09c4e;
}
.stat-title-placeholder {
  color: gray;
}

.stat-title-fav {
  flex-basis: 33%;
  padding-left: 15px;
}
.stat-title-price {
  padding-left: 15px;
  padding-right: 0px;
  /* margin: auto; */
  flex-basis: 25%;
}
.stat-title-sale {
  flex-basis: 40%;
  padding-left: 10px;
  padding-right: 5px;
}

.stat-data-price,
.stat-data-fav,
.stat-data-sale {
  margin-top: 10px;
  text-align: center;
  flex-wrap: wrap;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  color: #354264;
}
.stat-data-fav {
  flex-basis: 33%;
  padding-left: 12px;
}
.stat-data-price {
  padding-left: 12px;
  padding-right: 0px;
  flex-basis: 25%;
}
.stat-data-sale {
  flex-basis: 35%;
  padding-left: 6px;
}

.stat-title-locked-icon {
  height: 30px;
  margin-top: 10px;
  padding-left: 10px;
}

.content-functions {
  height: 200px;
}
.content-functions-hidden {
  height: 0px;
}
.extract-tags-lock-icon,
.export-lock-icon {
  width: 15px;
  padding: 0px 0px 3px 2px;
}
