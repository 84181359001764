.feature-section {
  padding-top: 7rem;
  padding-bottom: 7rem;
  background: linear-gradient(
    to bottom,
    #f09c4e 0%,
    #f09c4e 30%,
    #ffffff 80%,
    #ffffff 100%
  );
  color: black !important;
}
.features-icons .features-icons-item {
  max-width: 20rem;
  /* background-color: red; */
  /* background: url(./Polygon.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 90%; */
}
.feature-shape {
  position: relative;
  width: 100%;
}
.feature-item {
  background-color: white;
  border-radius: 50%;
  overflow: show;
  text-align: center;
  height: 300px;
  width: 300px;
  margin: 10px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}
.feature-title {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.06em;
}
.feature-description {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
}
