.callout {
  padding-top: 5rem 0;
}
.callout h2 {
  font-size: 3.5rem;
  font-weight: 700;
  display: block;
  max-width: 30rem;
}
.callout-image {
  width: 100%;
}
