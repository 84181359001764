.affiliate-description-image {
  right: 50px;
  padding-right: 50px;
  width: 400px;
}

.affiliate-description {
  border-radius: 25px;
  overflow: hidden;
  background-color: #f09c4e;
}
