/*!
 * Start Bootstrap - Stylish Portfolio v5.0.7 (https://startbootstrap.com/template-overviews/stylish-portfolio)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-stylish-portfolio/blob/master/LICENSE)
 */

/* h1,
h2,
h3 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 400;
} */

@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}

.ch1 {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 80px;
}

.ch2 {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 66px;
  letter-spacing: 0.06em;
}

.ch3 {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 54px;
  letter-spacing: 0.06em;
}

.ch4 {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 54px;
  letter-spacing: 0.06em;
}

.ch5 {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 54px;
  letter-spacing: 0.06em;
}

.cp {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.06em;
}

.cp2 {
  font-family: Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.06em;
}

.txt-dark-blue {
  color: #2e3a57;
}
.txt-blue {
  color: #475986;
}
.txt-blue:hover {
  text-decoration: none;
  color: #475986;
}
.txt-blue:active {
  text-decoration: none;
  color: #475986;
}
.txt-orange {
  color: #ef9b4e !important;
}

.txt-clickable:hover {
  background-color: #e1e1e1;
  cursor: pointer;
}

.txt-white {
  color: #ffffff;
}
.txt-light {
  color: lightgray;
}

.text-primary {
  color: #6575a7 !important;
}
.text-secondary {
  color: #ed9440 !important;
}

.btn-outline-primary {
  background-color: white !important;
  border-color: #6f80b2 !important;
  color: #6f80b2 !important;
  cursor: pointer !important;
  font-family: Verdana, Geneva, sans-serif !important;
}
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
  background-color: #6575a7 !important;
  border-color: #6575a7 !important;
  color: white !important;
}

.btn-primary {
  background-color: #6f80b2 !important;
  border-color: #6f80b2 !important;
  color: #fffef7 !important;
  font-family: Verdana, Geneva, sans-serif !important;
}
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #6575a7 !important;
  border-color: #6575a7 !important;
}

/* Orange button */
.btn-orange {
  padding: 25px 30px 25px 30px;
  background: linear-gradient(93.66deg, #ef9b4e 4.11%, #fbae67 86.29%);
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  cursor: pointer;
}
.btn-orange:focus,
.btn-orange:hover {
  background: #ed9440;
  color: #fef8de;
  text-decoration: none;
}
.btn-orange:active {
  background: #d98217;
  color: #fef8de;
  text-decoration: none;
}
.btn-orange-old-text {
  font-family: 'Montserrat', sans-serif;
  color: #fef8de;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.btn-text-h4 {
  font-family: 'Montserrat', sans-serif;
  color: #fef8de;
  font-size: 15px;
  /* font-weight: bold; */
}

.btn-large {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  padding: 15px 15px 15px 15px;
  line-height: 50px;
}
.btn-regular {
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
  padding: 10px 15px 10px 15px;
  line-height: 20px;
}

/* Dark Blue Button */
.btn-dark-blue-disabled,
.btn-dark-blue {
  /* width: 50px;
  height: 50px; */

  color: white;
  font-size: 20px;
  /* margin: 15px; */

  text-align: center;

  border-radius: 6px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}
.btn-dark-blue {
  background: #6b81b6;
  cursor: pointer;
}
.btn-dark-blue-disabled {
  background: lightgray;
  text-decoration: none;
}
.btn-dark-blue:focus,
.btn-dark-blue:hover {
  background: #546eab;
  color: white;
  text-decoration: none;
}
.btn-dark-blue:active {
  background: #4f68a1;
  color: white;
  text-decoration: none;
}

/* Black Button */
.btn-black {
  /* width: 50px;
  height: 50px; */
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  color: white;
  font-size: 20px;
  /* margin: 15px; */
  padding: 15px 15px 15px 15px;
  text-align: center;
  line-height: 50px;

  background: #1e1e1e;
  border-radius: 6px;
  /* box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25); */
}
.btn-black:focus,
.btn-black:hover {
  background: #2c2c2c;
  color: white;
  text-decoration: none;
}
.btn-black:active {
  background: #3c3c3c;
  color: white;
  text-decoration: none;
}

/* White Button */
/* Orange button */
.btn-white {
  padding: 10px 10px 10px 10px;
  background: white;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  font-family: 'Montserrat', sans-serif;
  color: #6b81b6;
  font-size: 20px;
  font-weight: bold;
  text-align: center;

  cursor: pointer;
  text-decoration: none;
}
.btn-white:focus,
.btn-white:hover {
  background: lightgray;
  text-decoration: none;
}
.btn-white:active {
  background: lightgray;
  text-decoration: none;
}

.blue-button {
  cursor: pointer;
}

.blue-button {
  color: #ffffff;
  background: #6b81b6;
}
.blue-button:hover {
  background: #546eab;
  color: white;
  text-decoration: none;
}
.blue-button:active {
  background: #4f68a1;
  color: white;
  text-decoration: none;
}
.blue-button-disabled {
  color: #ffffff;
  background-color: lightgray;
  cursor: default;
}

.bg-primary {
  background-color: #ed9440;
}

/* Select Picker */
.selectpicker-primary {
  padding: 7px;
  border-color: #6f80b2;
  border-radius: 4px;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 16px;
}

/* Custom Input */
.sheerba-input {
  font-family: Verdana, Geneva, sans-serif;
  padding: 10px 0px 10px 10px;
  border-color: #475986;
  border-width: 1px;
  border-radius: 4px;
  color: #475986;
}
.sheerba-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #475986;
  opacity: 0.6; /* Firefox */
}

/* Reuseable Classes Above */

h3 {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 23px;
}
p {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}
blockquote {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}
pre {
  font-family: Verdana, Geneva, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 23px;
}
body,
html {
  font-family: Verdana, Geneva, sans-serif;
  width: 100%;
  height: 100%;
}
.btn-xl {
  padding: 1.25rem 2.5rem;
}
.content-page {
  padding-top: 15rem;
  padding-bottom: 15rem;
}
.content-section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.content-section-heading h2 {
  font-size: 3rem;
}
.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase;
}

.text-light {
  color: black;
}
.text-faded {
  color: rgba(255, 255, 255, 0.7);
}

.map {
  height: 30rem;
}
@media (max-width: 992px) {
  .map {
    height: 75%;
  }
}
.map iframe {
  pointer-events: none;
}
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fffef7;
  background: rgba(52, 58, 64, 0.5);
  line-height: 45px;
}
.scroll-to-top:focus,
.scroll-to-top:hover {
  color: #fffef7;
}
.scroll-to-top:hover {
  background: #343a40;
}
.scroll-to-top i {
  font-weight: 800;
}
.modal-close-dark {
  top: 0px;
  right: 0px;
}
.background-fade {
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 50%;
  position: fixed;
  z-index: 2;
  display: block;
}

#sidebar-wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: #1d809f;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fffef7;
  background: rgba(52, 58, 64, 0.5);
  line-height: 50px;
  z-index: 999;
}
.menu-toggle:focus,
.menu-toggle:hover {
  color: #fffef7;
}
.menu-toggle:hover {
  background: #343a40;
}
.service-icon {
  background-color: #fffef7;
  color: #1d809f;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
}
.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.2);
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.portfolio-item .caption .caption-content {
  color: #fffef7;
  margin: auto 2rem 2rem;
}
.portfolio-item .caption .caption-content h2 {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.portfolio-item .caption .caption-content p {
  font-weight: 300;
  font-size: 1.2rem;
}
@media (min-width: 992px) {
  .portfolio-item {
    max-width: none;
    margin: 0;
  }
  .portfolio-item .caption {
    /* -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s; */
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 5rem;
  }
  .portfolio-item img {
    /* -webkit-transition: -webkit-clip-path 0.25s ease-out; */
    -webkit-clip-path: inset(-1px);
    clip-path: inset(-1px);
  }
  .portfolio-item:hover img {
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem);
  }
  .portfolio-item:hover .caption {
    background-color: rgba(29, 128, 159, 0.9);
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem);
  }
}
footer.footer {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

footer.footer .social-link {
  /* display: block; */
  height: 4rem;
  width: 4rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color: #1d809f;
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}

footer.footer .social-link:hover {
  background-color: #155d74;
  text-decoration: none;
}
a {
  color: #1d809f;
}
a:active,
a:focus,
a:hover {
  color: #155d74;
}

/* Affiliate */

.affiliate-popup-body-width {
  max-width: 450px;
}
.affiliateForm {
  background: white;
  position: fixed;
  overflow-y: auto;
  z-index: 3;
  width: 80%;
  max-width: 700px;
  max-height: 80%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  padding: 10px;
  border-radius: 7px;
}

.affiliate-footer-thing {
  min-height: 30rem;
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;
  /* background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 0,
      rgb(255, 255, 255) 100%
    ),
    url(../img/background3.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
}

/* Pricing */

.card-block {
  border-radius: 10px;
}

.spaced-line {
  padding-top: 6px;
  padding-bottom: 6px;
}

.light-text {
  color: lightgray;
}
.sign-in-form {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  /* text-align: center; */
}
.b1 {
  margin-top: 3rem;
}
.form {
  margin-top: 3rem;
}
.Form.Label {
  text-align: left;
}
.center {
  text-align: center;
}

.secondary-modal-blackout-background {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 2;
  background: #8e9bb5;
  display: block;
  top: 0;
  left: 0;
  right: 0;
}

/* From Extension */
/* standard blue
6b81b6 - standard | 57
788cba - lighter | 60
546eab - darker | 50 (7 darker than standard)
*/

/* Blue two
5a84f1 - standard | 65
3a6dee - darker | 58  (7 darker than standard)
*/

/* standard orange
f2a864 - standard | 67
 - lighter
f09642 - darker | 60  (7 darker than standard)
 */

p,
div,
h4,
h5,
.font-family {
  font-family: Arial, sans-serif;
  font-weight: 100;
}

h3 {
  text-align: center;
}

.use-font {
  font-family: Arial, sans-serif;
}

/* Modals */
.secondary-modal-basics {
  /* overflow-y: visible; */
  /* position: fixed; */
  z-index: 10004;
  /* opacity: 1; */
}
.secondary-modal {
  overflow-y: visible;
  position: fixed;
  z-index: 10004;
  opacity: 1;
  background: #f7f7f7;
  /* border: 2px solid orange; */
  display: block;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  text-align: center;
  border-radius: 10px;
}

/* Components */

.modal-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  color: black;
  font-size: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 50px;
}
.modal-close-dark {
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 20px;
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 50px;
}

/* Small adjusters */

.toggle-group {
  padding-top: 10px;
}

.allaround {
  padding: 20px;
}

.center {
  text-align: center;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}

/* Text */
.primary-txt-color {
  color: black;
}
.secondary-txt-color {
  color: gray;
}
.error-txt-color {
  color: red;
}
.white {
  color: white;
}
.body-text {
  font-size: 14px;
  font-family: Arial, sans-serif;
}
.secondary-txt {
  font-size: 10px;
  font-family: Arial, sans-serif;
}
.warning-text {
  font-size: 14px;
  color: black;
  font-family: Arial, sans-serif;
}

.invisible-txt-color {
  color: #f7f7f7;
}

/* To Replace... eek */

.payment-big-spacing {
  height: 30px;
  /* display: inline-block; */
}
.payment-small-spacing {
  height: 15px;
  /* display: inline-block; */
}

.spacer {
  height: 20px;
}

.logged-out-top-spacing {
  padding-top: 4rem;
}

.center-in-body {
  padding-top: 230px;
  margin: auto;
  width: 70%;
}
.next-in-center {
  padding-top: 20px;
  margin: auto;
  width: 80%;
  text-align: center;
}

.centerHorizontally {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.need-subscription-text {
  float: right;
  position: absolute;
  text-align: center;
  top: 90px;
  right: 10px;
}

.terms-table-area {
  /* clear: both;
   margin-top: 0px;
   height: 620px;
   width: 100%; */
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}
.logoPosition {
  padding-top: 10px;
}

.color {
  border-radius: 10px 10px 0px 0px;
  /* padding: 4px; */
  background-color: #81a5db;
}

.title-spinner {
  right: 10px;
}

.center-in-parent {
  margin-left: auto;
  margin-right: auto;
}

.clickable {
  cursor: pointer;
}
.inline {
  display: inline-block;
}
