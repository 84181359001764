.ErrorMessage,
.SubmitButton,
.SubmitButton--error {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.ErrorMessage {
  margin: 5px 0px 5px 0px;
  color: #ce4b5c;
  /* background-color: lightsalmon; */
}

.SubmitButton,
.SubmitButton--error {
  margin: 15px 0px 0px 0px;
  padding: 15px 15px 15px 15px;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;

  color: #fffcdc;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
}

/* 
regular: #EA9938 - 57
dark: #e78a18; - 50
darker: #d98217 - 47 
*/
.SubmitButton {
  background: linear-gradient(93.66deg, #ef9b4e 4.11%, #fbae67 86.29%);
  border-radius: 6px;
  color: #ffffff;
}
.SubmitButton:hover {
  background: #e78a18;
}
.SubmitButton:active {
  background: #d98217;
}

/* 
regular: #ffc7ee - 89
dark: #ffa3e3 - 82 
*/
/* d35f6e */
/* d97380 */
.SubmitButton--error {
  background-color: #de8793;
}
/* .SubmitButton--error:hover {
  background-color: #ffa3e3;
}
.SubmitButton--error:active {
  background-color: red;
} */

.Result {
  /* background-color: pink; */
  padding: 0px 30px 10px 30px;
}
.ResultTitle {
  /* background-color: lightskyblue; */
  font-size: 20px;
}
.ResultMessage {
  /* background-color: purple; */
  font-size: 15px;
}
.Form {
  margin: 5px 0px 5px 0px;
  padding: 0px 40px 0px 40px;
}
.loader-positioning {
  margin: 0px 8px 3px 0px;
}

.form-control {
  background: gray !important;
  background-color: #c1cff2 !important;
  margin: 2px;
  padding: 15px 10px 35px 10px !important;
}
