/* Free trial button */
.free-trial {
  border-color: #6575a7;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
}
.row-image {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}
.row-title {
  color: #354264;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* height: 50px; */
}
.row-title:link {
  color: #354264;
  text-decoration: none;
}
.row-title:hover {
  color: #354264;
  text-decoration: underline;
}
.table {
  border-radius: 10px;
}

.table-area {
  /* margin-top: 60px; */
  min-height: 550px;
  margin-bottom: 50px;
  clear: both;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 20px;
  color: #b5b6b5;
}
.table-above-area {
  display: flex;
}
.table-loading-indicator {
  position: relative;
  top: 80px;
}
