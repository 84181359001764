.feedback-modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 10003;
  background: #8e9bb5;
  top: 0;
  left: 0;
  right: 0;
}
.feedback-modal-container {
  position: fixed;
  display: flex;
  z-index: 10004;
  height: 100%;
  width: 100%;
}
.feedback-modal {
  position: relative;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;

  width: 500px;
  text-align: center;

  border-radius: 10px;
  /* padding: 0px 40px 0px 40px; */
  /* background: #8797bf; */
  background-color: #dfe2eb;

  user-select: none;

  box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.25);
}
.feedback-modal-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  color: white;
  font-size: 25px;
  position: absolute;
  top: 0px;
  right: 0px;
  line-height: 50px;
}
.feedback-title {
  padding: 30px 0px 20px 0px;
  border-radius: 10px 10px 0px 0px;

  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;

  background: #8797bf;
  color: #ffffff;
  border-bottom: solid 1px #777777;
}
.feedback-header-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;

  color: black;
  margin: 0px 0px 20px 0px;
}

.feedback-loader-center {
  margin-top: 30%;
  /* margin-bottom: 50%; */
}

.feedback-submit-button {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  margin: 20px 0px 20px 0px;
  padding: 15px 0px 15px 0px;
}
.feedback-form,
.feedback-form-with-messages {
  padding: 5px;
  background-color: white;
  color: black;
  /* width: 300px; */
  vertical-align: top;
  border-radius: 10px;
  width: 100%;
}
.feedback-form {
  height: 150px;
}
.feedback-form-with-messages {
  height: 100px;
  /* width: 95%; */
  /* padding: 0px 20px 0px 20px; */
}
.feedback-messaging-area {
  border-top: solid 1px #cfcece;
  padding: 10px 20px 5px 20px;
  background-color: #ced8f1;
  border-radius: 0px 0px 10px 10px;
}
.feedback-none-yet-area {
  padding: 15px 40px 5px 40px;
}
.feedback-messages-area {
  /* background-color: lightgreen; */
  height: 355px;
  overflow-y: scroll;
  /* overscroll-behavior-y: contain; */
  /* scroll-snap-type: y proximity;
  scroll-snap-align: end; */
  display: flex;
  flex-direction: column-reverse;
  /* margin-bottom: 10px; */
}

.feedback-user-message-box,
.feedback-sheerba-message-box {
  margin-bottom: 10px;
}
.feedback-user-message {
  margin-left: 50px;
  margin-right: 20px;
  background-color: whitesmoke;
}
.feedback-sheerba-message-box {
}

.feedback-user-message,
.feedback-sheerba-message {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px 10px 10px 10px;
  /* width: 200px; */
  text-align: left;
  border-radius: 15px;
}
.feedback-sheerba-message {
  margin-right: 50px;
  margin-left: 20px;
  background-color: #fdf4ec;
}
.feedback-sheerba-name {
  font-weight: bold;
}
