.video-area {
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}
.video-background {
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 50%;
  position: fixed;
  z-index: 2;
}

.video-modal {
  background: white;
  overflow-y: auto;
  z-index: 3;
  /* width: 80%; */
  /* max-width: 400px; */
  /* max-height: 80%; */
  /* display: block;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem; */
  border-radius: 7px;
}
.video-modal-close {
  width: 50px;
  height: 50px;
  cursor: pointer;
  text-align: center;
  font-family: Arial, sans-serif;
  color: black;
  font-size: 25px;
  margin-left: auto;
  line-height: 50px;
}
.video-modal-reg {
  /* padding-top: 4rem;
  padding-bottom: 4rem; */
  padding: 10px;
}
.video-modal-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
