.tag-area {
  /* position: relative; */
}
.tag-text {
}

.expandableViewTag {
  /* background-color: #f5f5f5; */
  border-radius: 6px;
  color: #354264;
  padding: 3px 0px 3px 0px;
  /* margin: 3px 3px 3px 3px; */
  width: 140px;
  cursor: pointer;
  font-size: 11px;
  font-family: Arial, sans-serif;
  font-weight: 80;
  text-align: center;
  /* float: left; */
  overflow-y: clip;
  /* position: absolute; */
  flex-basis: 50%;
}
.expandableViewTag:hover {
  color: #121621;
  background-color: #e6e6e6;
}
