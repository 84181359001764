.section {
  padding-top: 10px;
  text-align: left;
  color: #475986;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
}
.row {
  padding: 0px 60px 20px 60px;
}
.checkmark {
  width: 45px;
  flex-shrink: 0;
  /* width: 30px;
  flex: 0 0 30px; */
  /* flex-basis: 5%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-grow: 0; */
}
.line-item {
  padding: 4px 10px 0px 10px;
  float: right;
  max-lines: 2;
  flex-basis: 80%;
}
