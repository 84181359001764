.affiliate-top-cta {
  min-height: 30rem;
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 7rem;

  background-color: #fbfeff;
}
.affiliate-top-image {
  width: 100%;
}
