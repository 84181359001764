.emailForm {
  background: white;
  position: fixed;
  overflow-y: auto;
  z-index: 3;
  width: 80%;
  max-width: 400px;
  max-height: 80%;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  padding: 10px;
  border-radius: 7px;
}
