.register-button,
.log-in-button,
.step-1-description,
.step-1-further-description,
.step-1-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  user-select: none;
}

.logged-out-container {
  /* position: fixed; */
  display: flex;
  height: 100%;
  width: 100%;
}

.stage-main-area {
  height: 710px;
  max-width: 710px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 20px;
  color: #b5b6b5;
  /* border-radius: 10px 10px 10px 10px; */
}

.stage-logo-section {
  padding-right: 25px;
  padding-top: 50px;
  height: 100px;
  text-align: center;
  user-select: none;
}

.logged-out-mascot,
.logged-out-logo {
  height: 100px;
}

.progress-positioning {
  margin: 70px 0px 0px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  /* width: 600px; */
}

.progress-section {
  max-height: 100px;
  user-select: none;
}

.step-1-title {
  margin: 30px 0px 0px 0px;

  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: 0.03em;

  color: #475986;
}

.step-1-description {
  margin: 30px 0px 0px 0px;

  font-weight: 500;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 0.03em;

  color: #f09c4e;
}

.step-1-further-description {
  margin: 30px 30px 0px 30px;

  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.03em;

  /* color: #f09c4e; */
  color: #475986;
}

.log-in-button,
.register-button,
.google-button {
  /* width: 400px; */
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
  cursor: pointer;
  user-select: none;
}

/* 57 50 47 */
.log-in-button {
  margin-top: 40px;
  background: #6b81b6;
  box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 47px;

  font-weight: 400;
  font-size: 26px;
  line-height: 45px;
  letter-spacing: 0.03em;

  color: #ffffff;
}

.log-in-button:hover {
  background: #546eab;
}

.log-in-button:active {
  background: #4f68a1;
}

/* 96 89 86 */
.register-button {
  margin-top: 20px;
  background: #f4f4f4;
  box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 47px;

  font-weight: 400;
  font-size: 26px;
  line-height: 45px;
  letter-spacing: 0.03em;

  color: #f09c4e;
}

.register-button:hover {
  background: #e3e3e3;
}

.register-button:active {
  background: #dbdbdb;
}

.google-button {
  margin-top: 20px;
  background: #f4f4f4;
  box-shadow: 2px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 47px;

  font-weight: 400;
  font-size: 22px;
  line-height: 45px;
  letter-spacing: 0.03em;

  color: #3d3d3d;
  cursor: pointer;
}

.google-button:hover {
  background: #e8e8e8;
}

.google-button:active {
  background: #d5d5d5;
}

.google-icon {
  width: 22px;
  margin: 0px 20px 0px 0px;
}

.logged-out-background {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 80%;
  z-index: -1;
}

.Toastify__toast--success {
  background-color: #ffaf66 !important;
  padding: 20px !important;
  border-radius: 10px !important;
}

.Toastify__toast--warning {
  background-color: #6b81b6 !important;
  padding: 20px;
  border-radius: 10px !important;
}

.Toastify__toast--error {
  padding: 20px !important;
  border-radius: 10px !important;
}

.Toastify__toast-body {
  padding: 10px 30px 10px 30px !important;
  font-size: 16px;
  font-weight: bold;
}