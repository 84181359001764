.home-header {
  min-height: 50rem;
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: url(./home_header_background.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* @media (min-width: 992px) {
  .home-header {
    height: 100vh;
  }
} */
.home-deader-subtitle {
  /* font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;

  color: #475986; */
}

.home-header-detective {
  padding-top: 50px;
  /* width: 90%; */
  width: 100%;
  height: auto;
  position: relative;
  /* width: 350px; */
}

.home-play-line {
  width: 40px;
  height: 2px;
}

.header-video-section {
  cursor: pointer;
}
